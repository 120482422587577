import sha256 from 'crypto-js/sha256'
import encBase64 from 'crypto-js/enc-base64'
import encUTF8 from 'crypto-js/enc-utf8'
import aes from 'crypto-js/aes'
import randomWords from 'random-words'
export default {
  state: {
    pass: 'mINyRESDEcmOUh2RvtteYhPIWLoMuR',
    mot: null
  },
  getters: {
    generateCodeChallenge: () => {
      var array = new Uint32Array(28)
      window.crypto.getRandomValues(array)
      var code_verifier = Array.from(array, dec => ('0' + dec.toString(16)).substr(-2)).join('')
      var hash = sha256(code_verifier)
      var base64url = hash.toString(encBase64).replace(/=/g, '').replace(/\+/g, '-').replace(/\//g, '_')
      return base64url
    },
    generateState: function (state) {
      state.mot = randomWords()
      var e = aes.encrypt(state.mot, state.pass).toString()
      return e.replace(/\+/g, '-')
    },
    decodeState: (state) => (s) => {
      let bytes = aes.decrypt(s.replace(/\-/g, '+'), state.pass)
      let decrypted = bytes.toString(encUTF8)
      return decrypted === state.mot
    }
  },
  mutations: {},
  actions: {},
  modules: {}
}
